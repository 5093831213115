import React from 'react'
import { Grid } from '@mui/material'
import CountUp from 'react-countup';
import k1 from './image/k1.jpg'
import k2 from './image/k2.jpg'
import k3 from './image/b1.jpg'
import k4 from './image/b2.jpg'
function FooterX() {
  return (
    <div className=''>
             <div className='back_of_exp'>
             <div className='container'>

         <Grid container spacing={2}>
         <Grid item md={4} lg={4} xs={12} sm={12}>
         <br/><br/>
<h3 className='centeritallexptext'>
 <CountUp delay={8} start={30000000} end={30} /> + <br/>
<span className='centeritallexptexund'>Years of Experiences</span>
</h3>
<br/><br/>
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
         <br/><br/>
<h3 className='centeritallexptext'>
 <CountUp delay={8} start={13400000} end={10000} /> + <br/>
<span className='centeritallexptexund'>Projects Completed
</span>
</h3>
<br/><br/>
</Grid>

<Grid item md={4} lg={4} xs={12} sm={12}>
         <br/><br/>
<h3 className='centeritallexptext'>
 <CountUp delay={8} start={48090000} end={40} />  <br/>
<span className='centeritallexptexund'>Team Members

</span>
</h3>
<br/><br/>
</Grid>

         </Grid>
              
</div>


             </div>
<br/><br/>
<div className='container'>
<Grid container>
  <Grid item md={12} lg={12} xs={12} sm={12}>
  <h1 className='whyushead'>Foundation Kitchen & Bath</h1>
    <br/>  <br/>
  </Grid>


</Grid>

</div>
<Grid container>
<Grid item md={6} lg={6} xs={12} sm={12}>
<figure>
    <img src={k1} alt="Mountains"/>
    <figcaption>Kitchen</figcaption>
</figure>
</Grid>
<Grid item md={6} lg={6} xs={12} sm={12}>
<figure>
    <img src={k2} alt="Mountains"/>
    <figcaption>Kitchen</figcaption>
</figure>
</Grid>
<Grid item md={6} lg={6} xs={12} sm={12}>
<figure>
    <img src={k4} alt="Mountains"/>
    <figcaption>Bath</figcaption>
</figure>

</Grid>
<Grid item md={6} lg={6} xs={12} sm={12}>
<figure>
    <img src={k3} alt="Mountains"/>
    <figcaption>Bath</figcaption>
</figure>

</Grid>
</Grid>



    </div>
  )
}

export default FooterX