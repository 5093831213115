import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import National from './image/nfr.webp'
import cards from './image/cards.webp'

import foot1 from './image/foot1.webp'
import foot2 from './image/bbb.png'
import foot3 from './image/foot3.webp'
import logo from './image/loco.png'

import { Grid } from '@mui/material';

function Footer() {
  return (
    <div className='back_of_footer'>

<MDBFooter className='text-center text-lg-start '>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span className='color_of_footer_social'>OUR SOCIAL</span>
        </div>

        <div>
          <a href='https://www.facebook.com/aztechl' className='me-4 text-reset' target='_blank'>
            <FacebookIcon className='color_hover_social' />
          </a> &nbsp; &nbsp;
          <a href='https://twitter.com/aztechl' className='me-4 text-reset' target='_blank'>
            <TwitterIcon className='color_hover_social' />
          </a>
          
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon color='secondary' icon='gem' className='me-3' />
                <img src={logo} style={{width:"200px"}}/>
              </h6>
            
              <p>
               <img src={National} style={{width:"200px"}}/>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='3' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4 footerhead'>HOURS OF OPERATION</h6>
              <p>
              Monday to Friday
              </p>
              <p>
              8:00 AM to 5:00 PM
              </p>
              
            </MDBCol>

            <MDBCol md='3' lg='3' xl='3' className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4 footerhead'>SERVICE AREAS</h6>
              <p>
              Harlingen, TX
              </p>
              <p>
              McAllen, TX
              </p>
              <p>
              Brownsville, TX
              </p>
              <p>
              Laredo, TX
              </p>
              <p>
              Eagle Pass, TX
              </p>
              <p>
              San Antonio, TX
              </p>
            </MDBCol>

            <MDBCol md='3' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>  <img src={cards} style={{width:"150px"}}/></h6>
              <p>
              We accept checks and cash, all major credit cards
              </p>
              <p>
              Discounts Available
              </p>
              <p>
              Licensed & Insured
              </p>
              <p>
              47 Years of Combined Experience
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
<Grid container spacing={2}>

<Grid item md={4} lg={4} xs={12} sm={12}>

<br/>
<img src={foot1} style={{width:"170px" , position:"relative", top:"20px"}}/>
</Grid> 
<Grid item md={4} lg={4} xs={12} sm={12}>
<br/>

<img src={foot2} style={{width:"150px"}}/>
</Grid>

<Grid item md={4} lg={4} xs={12} sm={12}>

<br/>
<img src={foot3} style={{width:"170px" , position:"relative", top:"20px"}}/>
</Grid>
</Grid>
<br/>
      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2023 by Aztec Foundation Repair
       
      </div>
   
    </MDBFooter>           

    </div>
  )
}

export default Footer