import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import { useForm } from "react-hook-form";


import { TextField, Grid, Box } from "@mui/material";

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dmgie9j', 'template_dg9raft', form.current, 'g1CX-89xwIWRWmiUv')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };


  
  return (
    <Box>
      <form    ref={form} onSubmit={sendEmail}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
          
            <TextField
              fullWidth
              name="user_name"
            

              margin="normal"
              label={<span>Name <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
     
              InputProps={{ style: { color: '#797979' } }} // Change text color to green
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }} // Change label color to green
              color="success"
          
            />
            <TextField
              fullWidth
              name="user_email"
             

              margin="normal"
              label={<span>Email <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
     
              InputProps={{ style: { color: '#797979' } }} // Change text color to green
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }} // Change label color to green
              color="success"
              type="email"
            />
            <TextField
              fullWidth
           
              name="user_phoneNumber"
            
              margin="normal"
              label={<span>Phone Number <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
     
              InputProps={{ style: { color: '#797979' } }} // Change text color to green
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }} // Change label color to green
              color="success"
              type="tel"
            />
            <TextField
              fullWidth
              name="user_subject"
             
             
              margin="normal"
              label={<span>Subject <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
     
              InputProps={{ style: { color: '#797979' } }} // Change text color to green
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }} // Change label color to green
              color="success"
            />
            <TextField
              fullWidth
              margin="normal"
              name="user_Message"
              label={<span>Message <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
     
              InputProps={{ style: { color: '#797979' } }} // Change text color to green
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }} // Change label color to green
              color="success"
       
           
              multiline
              rows={4}
            />
            <button class="button-5" sx={{ mt: 2 }} type="submit" value="Send"  >Submit</button>
            
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
