import React from 'react'

function Error() {
  return (
    <div className='bg_of_whyuse'>
        
        
        
        
       <h1 style={{textAlign:"center"}}>
<br/><br/><br/><br/><br/>
   404 Page Not Found

   <br/><br/>

   
       </h1>
       <a href='/'>
       <div className='centeriterror'>  <button class="button-5" role="button" sx={{ mt: 2 }}>Back Home</button></div>

       </a>


     
    
    
    
    
    
    
    
    </div>
  )
}

export default Error