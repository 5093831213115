import React from 'react'
import { Grid } from '@mui/material'
import Form from './AppointmentForm'
import appointment from './ezgif.com-gif-maker.gif'
function Contact() {

  return (
    <div className=''>
      <br/><br/><br/><br/>
      <div className='container'>
      <Grid  container spacing={4}> 
      <Grid  item md={6} lg={6} xs={12} sm={12} > 
         
         <div className='container padding_top_map'>

         
       
    
        
         
             
             
             
             <img src={appointment} className='main_map_top' style={{height:"100%"}}/>
             
             
         
          
          

           
           
           
           
           </div>
        
        </Grid> 
        <Grid  item md={6} lg={6} xs={12} sm={12}> 
             <h3 className='form_contact_us_h1'>Get a Quote</h3>
             <hr class="gradient"/>
            
             <br/>
             <Form/>
         </Grid>
       
        </Grid>
      </div>

    
   
   
    
      <br/><br/><br/><br/>
      </div>
  )
}

export default Contact