import React from 'react'
import { Grid } from '@mui/material'
import Form from './Form'
import PhoneIcon from '@mui/icons-material/Phone';
function Contact() {
  const emailAddress = 'aztechouseleveling@gmail.com';
  const subject = 'Hello from Aztech';
  const body = 'This is the body of the email.';

  const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  return (
    <div className=''>
      <br/><br/><br/><br/>
      <div className='container'>
      <Grid  container spacing={4}> 
        <Grid  item md={6} lg={6} xs={12} sm={12}> 
             <h3 className='form_contact_us_h1'>Ways to Reach Us</h3>
             <hr class="gradient"/>
             <p className='form_contact_us_p'>Please complete the form below or you may email us at <a href={mailtoLink}>aztecaleveling@gmail.com</a> </p>
             <br/>
             <Form/>
         </Grid>
         <Grid  item md={6} lg={6} xs={12} sm={12} > 
         
         <div className='container padding_top_map'>
         <br/> 
         <h4 className='time_up_map'>AVAILABLE AT 8:00 AM – 5:00 PM</h4>
         
           <br/>
           <a  href='tel:+19566596468'><button class="button-5s" role="button" sx={{ mt: 2 }} >  
           
           <PhoneIcon/>&nbsp;&nbsp;
            +1 (956)659-6468  </button></a>
        
             <br/>  <br/>  <br/>  
          
          
           <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1KzAPiN9bacSVUdlxfvErGLFmueix2pU&ehbc=2E312F&noprof=1" className='main_map_top'></iframe></div>
        
        </Grid> 
        </Grid>
      </div>
      <br/><br/><br/>
      <div className='container'>
      <Grid  container spacing={2}> 
      <Grid  item md={5} lg={5} xs={12} sm={12}> 
             <h3 className='form_contact_us_h1'>Multiple cities in America </h3>
             <hr class="gradient"/>
             <p className='form_contact_us_p'>We're focused on expanding our reach to new regions in the future</p>
             
         </Grid>
      <Grid  item md={7} lg={7} xs={12} sm={12}> 
        </Grid> 
        </Grid>
      </div>
      <br/>   <br/><br/>
      <div className='container'>
      <Grid  container spacing={4}> 
      <Grid  item md={4} lg={4} xs={12} sm={12}> 
             <h3 className='form_contact_us_h1m'>HARLINGEN TX</h3>
             <hr class="gradient"/>
             <p className='form_contact_us_p'>
             Address : Harlingen, TX (78550)(78551)(78552)(78553)(78586)
             </p>
         </Grid>
      <Grid  item md={8} lg={8} xs={12} sm={12}> 
      <iframe src="https://www.google.com/maps/d/u/0/embed?mid=11VPYrxV8HPKVhX_npVkDW7g_FjAxf8M&ehbc=2E312F" className='map_all'></iframe>


        </Grid> 
        </Grid>
      </div>
      <br/>   <br/><br/>
      <div className='container'>
      <Grid  container spacing={4}> 
      <Grid  item md={4} lg={4} xs={12} sm={12}> 
             <h3 className='form_contact_us_h1m'>McAllen TX</h3>
             <hr class="gradient"/>
             <p className='form_contact_us_p'>
             ADDRESS : 2408 redbud ave. #B McAllen, TX 78504
             </p>
         </Grid>
      <Grid  item md={8} lg={8} xs={12} sm={12}> 

      <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1S6xIeEQbXvzk1Nu1GjQShAfTz65dEc0&ehbc=2E312F&noprof=1" className='map_all'></iframe>
        </Grid> 
        </Grid>
      </div>
      <br/>   <br/><br/>
      <div className='container'>
      <Grid  container spacing={4}> 
      <Grid  item md={4} lg={4} xs={12} sm={12}> 
             <h3 className='form_contact_us_h1m'>Brownsville TX</h3>
             <hr class="gradient"/>
             <p className='form_contact_us_p'>
             Address :  906 W. Levy Brownsville TX, 78521
             </p>
         </Grid>
      <Grid  item md={8} lg={8} xs={12} sm={12}> 
      <iframe src="https://www.google.com/maps/d/u/0/embed?mid=15czP4aM9r8ulTYQSnhNYWo1ts8caiaI&ehbc=2E312F&noprof=1" className='map_all'></iframe>
        </Grid> 
        </Grid>
      </div>
      <br/>   <br/><br/>
      <div className='container'>
      <Grid  container spacing={4}> 
      <Grid  item md={4} lg={4} xs={12} sm={12}> 
             <h3 className='form_contact_us_h1m'>Laredo TX</h3>
             <hr class="gradient"/>
             <p className='form_contact_us_p'>
             Address : Laredo, TX (78041)(78040)(78042)(78045)(78049)(88070)(88240)(78043)(78046)(88030)(88230)(88278)
             </p>
         </Grid>
      <Grid  item md={8} lg={8} xs={12} sm={12}> 


      <iframe src="https://www.google.com/maps/d/u/0/embed?mid=14z_5A_jwJSRWE_hJabSd_V6eGbUJT68&ehbc=2E312F&noprof=1" className='map_all'></iframe>
        </Grid> 
        </Grid>
      </div>
      <br/>   <br/><br/>
      <div className='container'>
      <Grid  container spacing={4}> 
      <Grid  item md={4} lg={4} xs={12} sm={12}> 
             <h3 className='form_contact_us_h1m'>Eagle Pass TX</h3>
             <hr class="gradient"/>
             <p className='form_contact_us_p'>
             Address : 431 Farm-To-Market Road 375, Eagle Pass, TX 78852
             </p>
         </Grid>
      <Grid  item md={8} lg={8} xs={12} sm={12}> 




      <iframe src="https://www.google.com/maps/d/u/0/embed?mid=129937qauSbeymdfaWN0DGlVfrjPQKfQ&ehbc=2E312F&noprof=1" className='map_all'></iframe>

        </Grid> 
        </Grid>
      </div>
      <br/>   <br/><br/>
      <div className='container'>
      <Grid  container spacing={4}> 
      <Grid  item md={4} lg={4} xs={12} sm={12}> 
             <h3 className='form_contact_us_h1m'>San Antonio TX</h3>
             <hr class="gradient"/>
             <p className='form_contact_us_p'>
             Address : San Antonio TX (entire city domain)
             </p>
         </Grid>
      <Grid  item md={8} lg={8} xs={12} sm={12}> 


      <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1JX7SOpPv92B0FCu6T7ifhMBRTjyXjBc&ehbc=2E312F&noprof=1" className='map_all'></iframe>



        </Grid> 
        </Grid>
      </div>
      <br/><br/><br/><br/>
      </div>
  )
}

export default Contact