import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FaqsAccordion() {
  const faqs = [
    {
      question: 'How much do you charge for an inspection and what is included?',
      answer:
        'We offer a free estimate to all HOMEOWNERS; otherwise, there is a service fee. Once an appointment has been scheduled, the inspector will meet with the owner(s) to provide some accurate measurements and an honest evaluation. If necessary, at the completion of the inspection, a written estimate will be given to the owner.',
    },
    {
      question: 'How soon can we come out to give an inspection?',
      answer:
        'Normally within the week but sometimes it can be the same day if not the next just depending on the schedule. Our inspectors will gladly give estimates till before it gets dark for those who may need late appointments.',
    },
    {
      question: 'Do we offer Same Day FINANCING?',
      answer:
        'Yes, we now offer financing through Old Union Financial. It’s an easy process that takes about 10-15 minutes to get approved once you fill out the credit application, and we submit your information online.',
    },
    {
        question: 'How long do the repairs take?',
        answer:
          'Generally it takes about 2-10 days depending on the scope of work and weather permitting. Sometimes there can be unexpected problems that may occur once the job has been started and could delay the completion time.',
      },
      {
        question: 'What is the average costs for foundation repairs?',
        answer:
          'An average repair runs between $4,000 – $8,000 depending on how much work is needed per home.',
      },
      {
        question: 'Are we licensed and insured?',
        answer:
          'Yes, we are licensed and insured',
      },
      {
        question: 'What are signs of foundation settlement?',
        answer:
          'Signs you may notice in the interior of home are: cracks in the walls and ceilings, the doors and windows may not open/close properly, uneven floors or cracks in the floor tiles, plumbing problems, or separation of walls, doors or windows. Signs you may notice on the exterior of home are: cracks in the brick or rock,cracks in the foundation, chimney gap from home or cracks/gaps in unusual places.',
      },
      {
        question: 'How does the foundation repair affect the PLUMBING?',
        answer:
          'When a foundation settles the plumbing pipes underneath the foundation can be damaged. Our licensed plumber performs plumbing tests on all of our jobs before and/or after the job is completed.',
      },
      {
        question: 'What other types of repairs can we do?',
        answer:
          'We can provide many services other than foundation repair s to your home such as: interior and exterior remodeling, carpentry work, plumbing back ups or leaks, exterior porch and deck repairs, and roof repairs. On staff with our company we have our own licensed plumber, experienced carpenters and foreman to take care of all the home repairs.',
      },
      {
        question: 'How long after repairs should I wait to do any type of remodeling?',
        answer:
          'We usually like to wait 4-6 weeks after repairs are completed just in-case there might me some slight shifting.',
      },
      {
        question: 'What type of WARRANTY do you offer?',
        answer:
          'We offer a 10 year warranty on our leveling with a lifetime warranty on our piers.Our warranties are transferable to the new homeowners for a fee.',
      },
  ];

  return (
    <div className='bg_of_whyus'> <br /> <br />
      <div className='container'>
        {faqs.map((faq, index) => (
          <React.Fragment key={index}>
            <Accordion className='back_of_accord' >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className='color_of_drop' />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                className='back_of_head'
              >
                <Typography className='head_of_faq'>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className='under_of_faq'>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
            <br />
          </React.Fragment>
        ))}
      </div> <br /> <br />
    </div>
  );
}

export default FaqsAccordion;
