import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";




import Navbar from '../../Navbar/Navbar'

export default function App() {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className='back_of_process'>
  
             <Navbar/>

             <br/>     <br/>     <br/>    <br/>   
             <h1 className='cracks_h1_faq' data-aos="fade-up">The Repair Process</h1>
             
             
               <br/>     <br/>   
               
               <p className='cracks_h1_faqs' data-aos="fade-up">Precision-driven drilled pier process ensuring structural stability from meticulous  <br/>   preparation to expert installation and meticulous cleanup.</p>
                 <br/>     <br/>      <br/>    <br/>   
     
    </div>
  );
}
