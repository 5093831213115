import React from 'react'

import { Grid } from '@mui/material'

import scan from './aztec.jpeg'
import scan1 from './image/scan1.webp'
import scan2 from './image/scan2.webp'


function Process() {
  return (
    <div className=''>
        <br/> <br/>
   <div className='container'>

<h3 className='form_contact_us_h1s'>House Leveling 101</h3>
<h5 className='form_contact_us_h1sp'>How the Drilled Pier Process Works</h5>
<br/> <br/>


 <Grid container spacing={2}>
 <Grid item md={8} lg={8} xs={12} sm={12}  >
 <div className='container padding_top_map'>
         <br/> 
         <h4 className='under_text_paras'> 1 . The first phase you will see will be the “site preparation.”</h4>
        
         <p className='under_text_para'>
         Our workers will lay down plastic or plywood to protect your yard. Then we will move any plants from the work area, next to your foundation, where piers will be installed, unless you have already taken care of this before we arrive. Typically the shrubs and/or plants are temporarily transplanted into the soil near the excavated area. We will break through concrete, or any other paved surfaces (sidewalks, driveways, patio areas), and dig access holes that are approximately 3ft wide x 3ft long x 3ft deep below your home’s beam. These holes are about the size of a common door mat.
         </p>



   </div>
<br/>

<div className='container padding_top_map'>
         <br/> 
         <h4 className='under_text_paras'> 2 . The next operation begins with the of installation of piers.”</h4>
         
         <p className='under_text_para'>
         Piers will be drilled at a slight angle between a depth of 4 ft or drill bit refusal (Depth of pier includes excavation from soil surface/top of ground to bottom of grade beam/slab) depending on soil conditions; rock or bit refusal, and/or contractor’s discretion. Piers will be 10 inches in diameter, and have a 24-inch bell to form the footings. Each pier will contain four each: #4 rebar steel placed vertically with 3 strips. (Piers will be as close as possible to the attached pier diagram.)All piers will contain 3,000 PSI concrete, with a 3-inch slump. If adverse soil conditions should occur, or any type of obstructions, we will have to dig large holes for the spread footings.
         </p>



   </div>
   <br/>
   <div className='container padding_top_map'>
         <br/> 
         <h4 className='under_text_paras'> 3 . The next step is the “leveling & stabilization process.</h4>
         
         <p className='under_text_para'>
         We will levelel and stabilize as much as the structure will permit. Our main intent/ purpose is to stop and minimize future foundation settlement (in areas where the piers are installed) . Hydraulic jacks are placed next to a pier. An experienced advanced technician directs the raising while carefully monitoring the reactions of the structure. When the raising is complete, steel shims are inserted between the beam and the piers to support the home/structure. After raising the foundation, the final step is the cleanup. Each hole is then back filled with soil, and concrete break-outs are then patched.
         </p>



   </div>
   <br/>
   <div className='container padding_top_map'>
         <br/> 
         <p className='under_text_para'>
         <span className='under_text_paras'> 4 . Plants and/or shrubs are replanted</span> and your yard, sidewalks and driveways are cleaned
         </p>



   </div>


 </Grid>

 <Grid item md={4} lg={4} xs={12} sm={12}  className='btc'>
<div className='btc'><img src={scan} style={{width:"100%"}}  className='fixedit '/>
<br/>

<br/>



</div>


 </Grid>

 </Grid>





   </div>

        <br/> <br/>
        </div>
  )
}

export default Process