import React from 'react';
import space from './space.mp4'
import logo from './cryplogo.gif'
import { Grid } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import a1 from './a1.gif'
import a2 from './a2.gif'
import a3 from './a3.gif'

function AztecCrypto() {
  return (
    <div className="background-video">
     
      <div className="content">
     <Grid container spacing={1}>
     <Grid item md={12} lg={12} xs={12} sm={12}> 
     
     <br/> 
        <br/> 
        <a href='/'>
        <button class="cybr-btn">
     Back
  <span aria-hidden class="cybr-btn__glitch">     Back</span>
 
</button>
           </a>
        
        <br/> <br/>
      <img src={logo} style={{width:"200px"}}/></Grid>

     </Grid>

       <br/> <br/>
        <h1 class="main-title">Aztec Crypto Currency</h1>
        <p class="main-titleu">Backed by Aztec House Leveling & Remodeling!</p>
        <br/><br/>
        <div><a href='https://www.youtube.com/@AztecHouseLeveling' className="round-content" target='_blank'>
      
      < YouTubeIcon   style={{position:"relative" , top:"5px" , color:"#c70000" , fontSize:"50px"}}/>
      </a> 
      &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; 
      <a href='https://twitter.com/azteccryptorgv?s=21&t=D9ZKCU4MRSaVbCAUgv5MWg' className="round-content" target='_blank'>
        
        
      <TwitterIcon  style={{position:"relative" , top:"5px" , color:"#1DA1F2" , fontSize:"50px"}}/>
        </a>
        
        
        </div> 


        <br/> 
        <br/> <br/><br/>
        <a href='https://azteccrypto.xyz'>
        <button class="cybr-btn">
       Main Website
  <span aria-hidden class="cybr-btn__glitch">Website</span>
 
</button>
           </a>
           <br/><br/>
        <br/> <br/>
        <p class="main-titleus">About Aztec Cryptocurrency</p>
        
<div className='container'>

<Grid container spacing={2}>  
        
        
      
      
        <Grid item md={6} lg={6} xs={12} sm={12} className='centerallofit'>

<img src={a1} style={{width:"100%"}}/>
<h1 class="main-titles">Invest!
</h1><br/>
<div className='container'>

<p class="main-titleuz">

Aztec House Leveling & Remodeling will siphon profits every month into the Token's liquidity pool increasing its value every month.

</p>
</div>


</Grid>
<Grid item md={6} lg={6} xs={12} sm={12} className='centerallofit'>

<img src={a2} style={{width:"100%"}}/>
<h1 class="main-titles">Play Games!
</h1><br/>
<div className='container'>

<p class="main-titleuz">

Use your Aztec Cryptocurrency and pay-to-play in our crypto arcade, where you can win tons of tokens!

</p>
</div>


</Grid>
        
        
        </Grid>

</div>
<div className='container'>

<Grid container spacing={2}>  
        
        
      
      
        <Grid item md={3} lg={3} xs={12} sm={12} className='centerallofit'>


</Grid>
<Grid item md={6} lg={6} xs={12} sm={12} className='centerallofit'>

<img src={a3} style={{width:"100%"}}/>
<h1 class="main-titles">Earn Rewards!
</h1><br/>
<div className='container'>

<p class="main-titleuz">

Unlock the value of your tokens, and sell them back to our liquidity pool. Cash in anytime on our secure platform.

</p>
</div>


</Grid>
<Grid item md={3} lg={3} xs={12} sm={12} className='centerallofit'>


</Grid>  
        
        </Grid>

</div>
     




<br/><br/>
        {/* Additional content */}
      </div>
    </div>
  );
}

export default AztecCrypto;
