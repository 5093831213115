import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";




import Navbar from '../../Navbar/Navbar'

export default function App() {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className='back_of_faqg'>
  
             <div className="container">

             <br/>     <br/>     <br/>    <br/>   
             <h1 className='cracks_h1_faq' data-aos="fade-up">Gallery</h1>
             
             
               <br/>     <br/>   
               
               <p className='cracks_h1_faqs' data-aos="fade-up">Step into our Gallery, where every image narrates a tale of transformation. </p>
                 <br/>     <br/>      <br/>    <br/> 

             </div>

             
     
    </div>
  );
}
