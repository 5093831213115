import React from 'react'
import { Grid } from '@mui/material'
function SectionThreeX() {
  return (
    <div className='backofwall'>
   <div className='container'>
     <Grid container spacing={2}>

     <Grid item md={6} xs={12} lg={6} className='back_on_top_wall'>


  <br/>    <h1 className='whyusheadw' data-aos="fade-up">Signs You May Have A Foundation Repair Problem…</h1>
    <br/>  <br/> 
    



    <ul className='whyusp'>
       <li data-aos="fade-up">Cracks in the slab</li>  
       <li data-aos="fade-up"> Cracks in wall or ceiling</li> 
       <li data-aos="fade-up">  Cracks in exterior rock or brick</li> 
       <li data-aos="fade-up"> 
 Plumbing Problem: Slow drainage or Backups</li> 
 <li data-aos="fade-up">   Doors do not open or close properly</li> 
    </ul>
  
  
  
  
  
  
    <br/> 




</Grid>


     </Grid>



   </div>


    </div>
  )
}

export default SectionThreeX