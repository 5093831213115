import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";




import Navbar from '../../Navbar/Navbar'

export default function App() {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className='back_of_faq'>
  
             <Navbar/>

             <br/>     <br/>     <br/>    <br/>   
             <h1 className='cracks_h1_faq' data-aos="fade-up">FAQs</h1>
             
             
               <br/>     <br/>   
               
               <p className='cracks_h1_faqs' data-aos="fade-up">From the inception to completion, we've addressed common queries to guide your <br/> architectural journey. Our FAQs are here to light the way.</p>
                 <br/>     <br/>      <br/>    <br/>   
     
    </div>
  );
}
