import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";






export default function App() {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className='back_of_appointment'>
  <div className="container">

  <br/>     <br/>      <br/>    <br/>  <br/>    <br/>   
             <h1 className='cracks_h1_faq' data-aos="fade-up" >Appointment</h1>
             
             
               <br/>     <br/>   
               
               <p className='cracks_h1_faqs' data-aos="fade-up">


               Schedule Your Home's Transformation: Book an Appointment with  Aztec House Leveling & Remodeling

               </p>
               <br/>     <br/>      <br/>    <br/>  <br/>    <br/> 


  </div>
             
     
    </div>
  );
}
