import React from 'react';
import { Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

function TopNavbar() {
  return (
    <div className='center_top_navbar'>
      <div className='container'> 
        <Grid container style={{ position: 'relative', top: '9px' }}>
          
        <Grid item md={2} lg={2} xs={2} sm={2}>
<a href='/Reviews' className='color_of_topnav_social'>Reviews</a>

        </Grid>
          <Grid item md={4} lg={4} xs={12} sm={12}>
            <p className='color_of_topnav_social'>
              OUR SOCIAL : &nbsp;
              <a href='https://www.facebook.com/aztechl' className='social-link' style={{ textDecoration: 'none', color: '#fff' }} target='_blank'>
                <FacebookIcon style={{ fontSize: '18px', position: 'relative', top: '4px' }} className='color_of_topnav_social'/>
              </a>
              &nbsp;              &nbsp;
              <a href='https://twitter.com/aztechl' className='social-link' style={{ textDecoration: 'none', color: '#fff' }} target='_blank'>
                <TwitterIcon style={{ fontSize: '18px', position: 'relative', top: '4px' }}  className='color_of_topnav_social'/>
              </a>{' '}
            </p>
          </Grid>
          <Grid item md={4} lg={4} xs={12} sm={12}  >
            <a
              href='tel:+19566596468'
              className='phone-number color_of_topnav_social'
              style={{ textDecoration: 'none',}}
            >
              PHONE NUMBER : +1 (956)659-6468
            </a>
          </Grid>
          <Grid item md={2} lg={2} xs={12} sm={12}>
<a href='/AztecCrypto' className='color_of_topnav_social color_azteccrypto' target='_blank'>Azteccrypto</a>

        </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default TopNavbar;
