import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


import { Grid } from '@mui/material';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Navigation, Autoplay, Mousewheel, Keyboard } from 'swiper/modules';

import Navbar from '../../Navbar/Navbar'

export default function App() {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
  
      <Swiper
         effect={'coverflow'}
         grabCursor={true}
         centeredSlides={true}
         slidesPerView={'auto'}
         coverflowEffect={{
           rotate: 50,
           stretch: 0,
           depth: 100,
           modifier: 1,
           slideShadows: true,
         }}
         nabigation={true}
         pagination={true}
         modules={[Autoplay  , Navigation]}
         className="mySwiper"
      >
        <SwiperSlide className='back_of_slider'>
        <br/>
        <br/> <br/> <br/> <br/> <br/> 
        <div className='container'>

        <Grid container>
                      <Grid item md={8} lg={8} xs={12} sm={12} className='back_of_para_top'>
                                                  <h2 className='cracks_h1' data-aos="fade-up"> Don't Wait Too Long!</h2>
                                                  <br/>
                                                  <p className='crack_p' data-aos="fade-up">
 <span className='color_war'>WARNING SIGNS:</span> Poor Water Drainage. Cracks in Your Drywall Bowing Walls. Door Gaps. Sticking Windows. Moisture in Your Crawlspace Walls Are Separating From the House. Uneven and Sagging Floors.
</p>


                                       </Grid>
                                       <Grid item md={4} lg={4} xs={12} sm={12} className=''>



                                       </Grid>
                                       
                      </Grid>

        </div>
        
        
        </SwiperSlide>
        <SwiperSlide className='back_of_slider1'>
        <br/> <br/> <br/> <br/> <br/> 
        <div className='container'>

                      <Grid container>
                      <Grid item md={8} lg={8} xs={12} sm={12} className='back_of_para_top'>
                                                  <h2 className='crack_h1'> <span style={{color:"#fff"}} data-aos="fade-up">CRACKED</span> FOUNDATION?</h2>
                                                  <br/>
                                                  <p className='crack_p' data-aos="fade-up">Aztec House Leveling and Remodeling offers foundation repair, floor & concrete leveling, remodeling, roofing, and plumbing services to the South Texas residential and commercial properties.

</p>
                                       </Grid>
                                       <Grid item md={4} lg={4} xs={12} sm={12} className=''>



                                       </Grid>
                                       
                      </Grid>

        </div>
        
        
        </SwiperSlide>
        <SwiperSlide className='back_of_slider2'>
        <br/>
        <br/> <br/> <br/> <br/> <br/> 
        <div className='container'>

                      <Grid container>
                      <Grid item md={9} lg={9} xs={12} sm={12} className='back_of_para_top'>
                                                  <h2 className='crack_h1' data-aos="fade-up"> <span style={{color:"#fff"}}>FREE </span>REMODEL ESTIMATE</h2>
                                                  <br/>
                                                  <p className='crack_ps' data-aos="fade-up">FINANACING AVAILABLE
                                                  <br/> 
2023

</p>
                                       </Grid>
                                       <Grid item md={3} lg={3} xs={12} sm={12} className=''>



                                       </Grid>
                                       
                      </Grid>

        </div>
        
        
        </SwiperSlide>
      
    
      </Swiper>
     
    </>
  );
}
