import React from "react";
import { useMediaQuery } from "@mui/material";
import Hero from "../components/Desktop/HeroGallery";
import MobileHero from "../components/Desktop/MobileHeroGallery";
import Footer from "../components/Desktop/Footer";
import Navbar from "../Navbar/Navbar";
import TopNavbar from "../Navbar/TopNavbar";
import Gallery from './Error/Error'
import AnimatedCursor from "react-animated-cursor"

import MobileTopNavbar from "../Navbar/MobileTopNavbar";
function YourComponent() {
  // Define your custom breakpoints

  const desktopBreakpoint = useMediaQuery("(min-width: 1450px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {desktopBreakpoint && (
        <div>
          {/* Content for desktop view */}
          <TopNavbar/>
          <div className='center_top_navbar'>  < Navbar/> </div>
     
         <Gallery/>
       



          <Footer/>  
        


           <AnimatedCursor
      innerSize={8}
      outerSize={8}
      color='193, 11, 111'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
    />
        </div>
      )}
      {tabletBreakpoint && (
        <div>
          {/* Content for tablet view */}
          <MobileTopNavbar/>
         <div className='center_top_navbar'>  < Navbar/> </div>
 
     
         <Gallery/>
               <Footer/>  
        </div>
      )}
      {mobileBreakpoint && (
        <div>
          {/* Content for mobile view */}
          <MobileTopNavbar/>
         <div className='center_top_navbar'>  < Navbar/> </div>
       

     
         <Gallery/>
               <Footer/>  
        </div>
      )}
      
    </div>
  );
}

export default YourComponent;
