import { useForm } from "react-hook-form";
import { useState , useRef  } from 'react';

import emailjs from '@emailjs/browser';

import { TextField, Grid, Box } from "@mui/material";
import { Select, MenuItem } from "@mui/material";

export default function ContactForm() {
    const [selectedSubject, setSelectedSubject] = useState('');

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
  
  
  
    const handleSubjectChange = (event) => {
      setSelectedSubject(event.target.value);
    };



    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_wy103ik', 'template_fqq4vbc', form.current, 'Cjeza8kyXHEw6oOHg')
        .then((result) => {
            console.log(result.text);
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        });
    };
  
  
    

  return (
    <Box>
      <form   ref={form} onSubmit={sendEmail}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
        
              name="user_name"

              margin="normal"
              label={<span>Name <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
     
              InputProps={{ style: { color: '#797979' } }} // Change text color to green
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }} // Change label color to green
              color="success"
          
            />
            <TextField
              fullWidth
              name="user_email"
              margin="normal"
              label={<span>Email <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
     
              InputProps={{ style: { color: '#797979' } }} // Change text color to green
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }} // Change label color to green
              color="success"
              type="email"
            />
            <TextField
              fullWidth
           
              
              name="user_phoneNumber"
              margin="normal"
              label={<span>Phone Number <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
     
              InputProps={{ style: { color: '#797979' } }} // Change text color to green
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }} // Change label color to green
              color="success"
              type="tel"
            />
           


<Select
              fullWidth
              value={selectedSubject}
              onChange={handleSubjectChange}
              error={!!errors.subject}
              label={<span>Subject <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
              InputProps={{ style: { color: '#797979' } }}
              className='back_of_field'
              name='user_Service'
              inputProps={{
                classes: {
                  icon: 'select-icon-color',
                },
              }}
              color="success"
            >
              <MenuItem value="" disabled>
                Select a Service
              </MenuItem>
              <MenuItem value="Estimate">Estimate</MenuItem>
              <MenuItem value="Foundation">Foundation</MenuItem>
              <MenuItem value="Remodel">Remodel</MenuItem>
              <MenuItem value="Concrete">Concrete</MenuItem>
            </Select>

            
            <TextField
              fullWidth
              margin="normal"
              label={<span>Comment <span style={{ color: '#FFD166' }}>*</span></span>}
              variant="filled"
     
              InputProps={{ style: { color: '#797979' } }} // Change text color to green
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }} // Change label color to green
              color="success"
              name="user_Comment"
           
              multiline
              rows={4}
            />
            <button class="button-5" role="button" sx={{ mt: 2 }}>Submit Request</button>
            
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
