import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";




import Navbar from '../../Navbar/Navbar'

export default function App() {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className='back_of_review'>
  
            
<div className="container">

<br/>     <br/>     <br/>    <br/>      <br/>      <br/>   
             <div data-aos="fade-up">  <h1 className='cracks_h1_faq' >Reviews</h1></div>
           
             
             
               <br/>     <br/>   
               <div data-aos="fade-up"></div>
               <p className='cracks_h1_faqs' data-aos="fade-up">
                

               We're humbled by the kind words of our clients, and it's their feedback that drives us<br/>  to continuously innovate and excel.

               </p>
                 <br/>     <br/>      <br/>    <br/>      <br/>      <br/>   
     

</div>
            
    </div>
  );
}
