import React from 'react';
import ImageGallery from 'react-image-gallery';

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('./Gallery', false, /\.(webp)$/));

const imageObjects = images.map((image, index) => ({
  original: image,
  thumbnail: image,
}));

function Gallery() {
  return (
    <div className='bg_of_whyus'>
      <br/><br/><br/> <br/><br/><br/> <br/><br/><br/>
      <div className='container'>
        <ImageGallery items={imageObjects} />
      </div>
      <br/><br/><br/> <br/><br/><br/> <br/><br/><br/>
    </div>
  );
}

export default Gallery;
