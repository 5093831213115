import React from "react";
import { useMediaQuery } from "@mui/material";
import Hero from "../components/Desktop/Hero";
import MobileHero from "../components/Desktop/HeroMobile";
import SwapDesign from "../components/Desktop/SwapDesign";
import SectionThree from "../components/Desktop/SectionThreeX";
import Footer from "../components/Desktop/Footer";
import Defi from '../components/Desktop/FooterX'
import Yield from '../components/Desktop/Yield'
import AnimatedCursor from "react-animated-cursor"
import Navbar from "../Navbar/Navbar";
import TopNavbar from "../Navbar/TopNavbar";
import MobileTopNavbar from "../Navbar/MobileTopNavbar";

function YourComponent() {
  const desktopBreakpoint = useMediaQuery("(min-width: 1450px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {desktopBreakpoint && (
        <div>
          {/* Content for desktop view */}
          <TopNavbar/>
          <Hero/>
          <Navbar/>
          <SwapDesign/>
          <SectionThree/>
          <Yield/>
          <Defi/>
          <Footer/>  
          <AnimatedCursor
      innerSize={8}
      outerSize={8}
      color='193, 11, 111'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
    />
        </div>
      )}
      {tabletBreakpoint && (
        <div>
          <MobileTopNavbar/>
            <div className='center_top_navbar'>  < Navbar/> </div>
          <MobileHero />
          
          <SwapDesign/>
          <SectionThree/>
          <Yield/>
          <Defi/>
          <Footer/>    
          
        </div>
      )}
      {mobileBreakpoint && (
        <div>
            <MobileTopNavbar/>
            <div className='center_top_navbar'>  < Navbar/> </div>
          <MobileHero />
          
          <SwapDesign/>
          <SectionThree/>
          <Yield/>
          <Defi/>
          <Footer/>  
         

        </div>
      )}
      
    </div>
  );
}

export default YourComponent;
