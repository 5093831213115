import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import AztecCrypto from "./AztecCrypto/AztecCrypto";
import './Aztec.scss'

function YourComponent() {
  const [isLoading, setIsLoading] = useState(true);

  const desktopBreakpoint = useMediaQuery("(min-width: 1450px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  useEffect(() => {
    // Simulating loading delay, replace setTimeout with your actual data fetching logic
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000); // Change the timeout duration as needed

    return () => clearTimeout(loadingTimeout);
  }, []);

  if (isLoading) {
    return <div style={{backgroundColor:"#000"}}><div class="containerz">
    <div id='loader'>
      <div id='title' class='flex'>
        <p class='loading-text'>LOADING AZTEC Crypto Currency</p>
        <div class='therefore'>∴</div>
        <p class="loading-number">%</p>
      </div>
      <div id='loading-bar-border'>
        <div class='loading-bar'>
        </div>
      </div>
      <div id='warning'>
        <p>
          <div class='exclamation'>!</div>
          &nbsp CAUTION, Do not turn off.
        </p>
      <div id='line-cascates'></div>
      </div>
    </div>
  </div></div>; // You can replace this with your custom loader component
  }

  return (
    <div>
      {desktopBreakpoint && (
        <div className="desktop">
          {/* Content for desktop view */}
          <AztecCrypto />
        </div>
      )}
      {tabletBreakpoint && (
        <div className="tablet">
         <AztecCrypto />
        </div>
      )}
      {mobileBreakpoint && (
        <div className="mobile">
            <AztecCrypto />
        </div>
      )}
    </div>
  );
}

export default YourComponent;
