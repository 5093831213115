import React from 'react'
import { Grid } from '@mui/material'
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import rev1 from './image/rev1.png'
import rev2 from './image/rev2.png'
import rev3 from './image/rev3.png'
import rev4 from './image/rev4.png'



function Testimonials() {
  const [value, setValue] = React.useState(5);
  return (
    <div className='container'><br/><br/><br/>
         <h4 className='voices' data-aos="fade-up">Some Voices of <span style={{color:" #e7a900"}}>satisfaction,</span>  stories <br/>of transformation!</h4>
         <br/><br/><br/>
            <Grid container spacing={2}>

            <Grid item md={6} lg={6} xs={12} sm={12}> 

  <div className='card_rev_b' data-aos="zoom-in">

   <p className='head_of_rev_p'>I had a great experience with this company.  They fixed my foundation that another company had tried to fix but couldn't.  These guys knew where the problem was and weren't just trying to sell me the entire farm. The company that I paid a bunch of money to didn't even know what they were doing and refused to honor their so called warranty.  Aztec took care of my house and didn't poke my eyes out. Great group of guys,  I'm extremely happy with them</p>
   <Grid container>
   <Grid item md={6} lg={6} xs={12} sm={12}>
   <Stack direction="row" spacing={2}>
      <Avatar alt="Remy Sharp" src={rev1} />
      <h4 className='head_of_rev'> victor De La Cruz</h4>
    </Stack>

    
    </Grid>  <Grid item md={6} lg={6} xs={12} sm={12}>
    <h4 className='head_of_rev' style={{textAlign:"end"}}> <Rating name="read-only" value={value} readOnly /></h4>

    </Grid>


   </Grid>
   
  

  </div>
              
</Grid>



<Grid item md={6} lg={6} xs={12} sm={12}>

<div className='card_rev_b' data-aos="zoom-in">

 <p className='head_of_rev_p'>


 We were very pleased with the work provided. Aztec was punctual and performed the services as agreed. The foreman was sure to keep us informed of the project work and explained what they were doing throughout each step. They cleaned up very well after project completion. The workers were very conscientious of our property and appeared to enjoy and take pride in their work. After leveling was completed they came inside and repaired the stress fractures as well and took care to minimize tracking and mess in our home. Jesse Capetillo and Mr. Alexander were very pleasant to work with

 </p>
 <Grid container >
 <Grid item md={6} lg={6} xs={12} sm={12}>
 <Stack direction="row" spacing={2}>
 <Avatar style={{backgroundColor:"#964B00"}}>O</Avatar>
    <h4 className='head_of_rev'> Otis Chubick</h4>
  </Stack>

  
  </Grid>  <Grid item md={6} lg={6} xs={12} sm={12}>
  <h4 className='head_of_rev' style={{textAlign:"end"}}> <Rating name="read-only" value={value} readOnly /></h4>

  </Grid>


 </Grid>
 


</div>
            
</Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={2}>

<Grid item md={7} lg={7} xs={12} sm={12}>

<div className='card_rev_b' data-aos="zoom-in">

<p className='head_of_rev_p'>

Extremely satisfied customer. Had a couple of exterior remodleing jobs done by Aztec House Leveling and Remodeling. Jesse M and crew did an awesome job. When Jesse C quoted my job I knew they would be more than a handyman, but the craftsmanship and work ethic is well worth the extra cost, not to mention the 10 year warranty. Try getting a handyman to give you a 10 year warranty. My project took about 4 weeks, but at the end of every day the crew cleaned up and made things pleasant. No piles of debris or work equipment in the way. If you’re considering a project for your home, give this company a chance, I did and I’m very happy that I did.

</p>
<Grid container>
<Grid item md={6} lg={6} xs={12} sm={12}>
<Stack direction="row" spacing={2}>
<Avatar alt="Remy Sharp" src={rev2} />
<h4 className='head_of_rev'>William Quellhorst</h4>
</Stack>


</Grid>  <Grid item md={6} lg={6} xs={12} sm={12}>
<h4 className='head_of_rev' style={{textAlign:"end"}}> <Rating name="read-only" value={value} readOnly /></h4>

</Grid>


</Grid>



</div>
  
</Grid>



<Grid item md={5} lg={5} xs={12} sm={12}>

<div className='card_rev_b' data-aos="zoom-in">

<p className='head_of_rev_p'>

5 new double pane windows painted 2 bedrooms, all the ceilings in my mobile home, leveled my mobile network rie downs new insulation underneath  and all insulation on the outside of my place and then all new vinyl  siding, plus new skirting all around.  The workers were always on time and did well on all the work. Also power washed my place and carport area.

</p>
<Grid container >
<Grid item md={6} lg={6} xs={12} sm={12}>
<Stack direction="row" spacing={2}>
<Avatar style={{backgroundColor:"#808080"}}>L</Avatar>
<h4 className='head_of_rev'>Linda Day</h4>
</Stack>


</Grid>  <Grid item md={6} lg={6} xs={12} sm={12}>
<h4 className='head_of_rev' style={{textAlign:"end"}}> <Rating name="read-only" value={value} readOnly /></h4>

</Grid>


</Grid>



</div>

</Grid>
</Grid>
<br/><br/>
<Grid container spacing={2}>

<Grid item md={6} lg={6} xs={12} sm={12}>

<div className='card_rev_b' data-aos="zoom-in">

<p className='head_of_rev_p'>
Aztec did work on my brothers house and my brother is very picky on who he wants to do the job. He seemed very satisfied with the work they did so I had them come and estimate my house. The salesman that came out was very respectful and polite as was the Owner. They were in and out and did a very good job.

</p>
<Grid container>
<Grid item md={6} lg={6} xs={12} sm={12}>
<Stack direction="row" spacing={2}>
<Avatar alt="Remy Sharp" src={rev3} />
<h4 className='head_of_rev'>William Alvarado</h4>
</Stack>


</Grid>  <Grid item md={6} lg={6} xs={12} sm={12}>
<h4 className='head_of_rev' style={{textAlign:"end"}}> <Rating name="read-only" value={value} readOnly /></h4>

</Grid>


</Grid>



</div>
  
</Grid>



<Grid item md={6} lg={6} xs={12} sm={12}>

<div className='card_rev_b' data-aos="zoom-in">

<p className='head_of_rev_p'>
I hired Aztec House Leveling to remodel my bathroom and the end result looks absolutely amazing, They were professional and on time every day. I highly recommend them for all your remodeling needs.

</p>
<Grid container >
<Grid item md={6} lg={6} xs={12} sm={12}>
<Stack direction="row" spacing={2}>
<Avatar alt="Remy Sharp" src={rev4} />
<h4 className='head_of_rev'>Tiffany Marie</h4>
</Stack>


</Grid>  <Grid item md={6} lg={6} xs={12} sm={12}>
<h4 className='head_of_rev' style={{textAlign:"end"}}> <Rating name="read-only" value={value} readOnly /></h4>

</Grid>


</Grid>



</div>

</Grid>
</Grid>
         <br/><br/>
         <Grid container spacing={2}>

<Grid item md={8} lg={8} xs={12} sm={12}>

<div className='card_rev_b' data-aos="zoom-in">

<p className='head_of_rev_p'>
The guys were very friendly and professional.  Never have I ever witnessed how this was done before, so it was quite an experience.  Got the house back up to being level.  Gives a 10-year warranty (which can be passed to the new owner).  Can for a price extend the warranty for another 10 years.  Comes back and checks it in 6 months to see if it needs tweaked a little.  Uses quality concrete slabs and blocks with steel shims - lasts longer. Very happy with the job they performed.

</p>
<Grid container>
<Grid item md={6} lg={6} xs={12} sm={12}>
<Stack direction="row" spacing={2}>
<Avatar style={{backgroundColor:"#808080"}}>L</Avatar>
<h4 className='head_of_rev'>Lois Huddlestun</h4>
</Stack>


</Grid>  <Grid item md={6} lg={6} xs={12} sm={12}>
<h4 className='head_of_rev' style={{textAlign:"end"}}> <Rating name="read-only" value={value} readOnly /></h4>

</Grid>


</Grid>



</div>
  
</Grid>




</Grid>
         
         
         
         
         
         
         <br/> 
         <br/></div>
  )
}

export default Testimonials