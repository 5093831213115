import React from "react";
import { useMediaQuery } from "@mui/material";
import Hero from "../components/Desktop/HeroAppointment";
import MobileHero from "../components/Desktop/MobileHeroAppointment";
import Footer from "../components/Desktop/Footer";
import Appointment from "../Appointment-Form/Appointment";
import MobileTopNavbar from "../Navbar/MobileTopNavbar";
import AnimatedCursor from "react-animated-cursor"
import Navbar from "../Navbar/Navbar";
import TopNavbar from "../Navbar/TopNavbar";

function YourComponent() {
  // Define your custom breakpoints

  const desktopBreakpoint = useMediaQuery("(min-width: 1450px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {desktopBreakpoint && (
        <div>
          {/* Content for desktop view */}
          <TopNavbar/>
    <Hero/>
     
<Appointment/>


          <Footer/>  
          
          
           <Navbar/>



          <AnimatedCursor
      innerSize={8}
      outerSize={8}
      color='193, 11, 111'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
    />
        </div>
      )}
      {tabletBreakpoint && (
        <div>
          {/* Content for tablet view */}
          <MobileTopNavbar/>
          <div className='center_top_navbar'>  < Navbar/> </div>
         
    <MobileHero/>
     
<Appointment/>


          <Footer/> 
        </div>
      )}
      {mobileBreakpoint && (
        <div>
          {/* Content for mobile view */}
          <MobileTopNavbar/>
          <div className='center_top_navbar'>  < Navbar/> </div>
         
    <MobileHero/>
     
<Appointment/>


          <Footer/>  
        </div>
      )}
      
    </div>
  );
}

export default YourComponent;
