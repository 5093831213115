import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeContext } from "./Context/ThemeContext";
import Home from "./pages/HomeX";
import Faq from "./pages/Faq";
import Reviews from "./pages/Reviews";
import Process from './pages/Process';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery'
import Appointment from './pages/Appointment'
import AztecCrypto from './pages/AztecCrypto'
import Error from "./pages/Error";

import theme from "./Theme";
import "rsuite/styles/index.less";
import "./App.css";
import "./style.scss";

function App() {
  const [darkTheme, setDarkTheme] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay for demonstration purposes
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 10);

    // Clean up the timeout when the component unmounts
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>AZTEC | House Leveling & Remodeling co.</title>
        <meta name="AZTEC | House Leveling & Remodeling co." content="AZTEC | House Leveling & Remodeling co." />
      </Helmet>
      <ThemeContext.Provider value={{ darkTheme, setDarkTheme }}>
        {isLoading ? (
          // Display the loading screen
          <div className="loading-screen">
  
       
          </div>
        ) : (
          // Render the actual content once loading is complete
          <Router>
            <Routes>
              <Route path="/" exact={true} element={<Home />} />
              <Route path="/Faq" exact={true} element={<Faq />} />
              <Route path="/Reviews" exact={true} element={<Reviews />} />
              <Route path="/Contact" exact={true} element={<Contact />} />
              <Route path="/Process" exact={true} element={<Process />} />
              <Route path="/Gallery" exact={true} element={<Gallery />} />
              <Route path="/Appointment" exact={true} element={<Appointment />} />
              <Route path='/azteccrypto' exact={true} element={<AztecCrypto />} />
              <Route path="*" exact={true} element={<Error />} />
            </Routes>
            
          </Router>
        )}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
}

export default App;
