import React, { useState, useEffect } from "react";
import "../components/styles.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import images from "./logo/loco.png";
import "./Navbars.css";
import { Grid } from "@mui/material";
import menu from './logo/Menu.svg'
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [showLaunchButton, setShowLaunchButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      if (window.scrollY > 700) {
        setShowLaunchButton(true);
      } else {
        setShowLaunchButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`app__navbar${isSticky ? " sticky" : ""}`} style={{zIndex:"100"}}>
      <Grid container>

              <a href="./">    <div className="app__navbar-logo">
              <a href="./">       <img src={images} style={{width:"70px"}}/></a>

      </div></a>
        
             
      </Grid>
  
      <>   <ul className="app__navbar-links">
        <li className="p__opensans">
          <a
            href="./"
           
            style={{ color: "#fff", fontWeight: "700", textDecoration: "none" }}
            className="a"
          >
          Home
          </a>
        </li>{" "}
        <li className="p__opensans">
          <a
            href="/Process"
            style={{ color: "#fff", fontWeight: "700", textDecoration: "none" }}
            className="a"
          >
         Process
          </a>
        </li>{" "}
        <li className="p__opensans">
          <a
            href="/Faq"
            style={{ color: "#fff", fontWeight: "700", textDecoration: "none" }}
            className="a"
          >
        Faqs
          </a>
        </li>{" "}
        <li className="p__opensans">
          <a
            href="/Gallery"
            style={{ color: "#fff", fontWeight: "700", textDecoration: "none" }}
            className="a"
          >
       Gallery
          </a>
        </li>{" "}
        <li className="p__opensans">
          <a
            href="/Appointment"
            style={{ color: "#fff", fontWeight: "700", textDecoration: "none" }}
            className="a"
          >
       Appointment
          </a>
        </li>{" "}
        <li className="p__opensans">
          <a
            href="/Contact"
            style={{ color: "#fff", fontWeight: "700", textDecoration: "none" }}
          >
            <button class="button-33" role="button" style={{  fontWeight: "700", textDecoration: "none" }}>  Contact</button>
    
          </a>
        </li>{" "}
    
      </ul></>
   


      <div className="app__navbar-smallscreen">
      <a  onClick={() => setToggleMenu(true)}>   <img src={menu}  /></a>
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <AiOutlineClose
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <br />
            <br />
            <ul className="app__navbar-smallscreen_links">
              <li className="li">
                <a
                  href="/"
                  target="_blank"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "500" , fontSize:"25px" , color:"#fff" }}
                >
                Home
                </a>
              </li>
             
              <li className="li">
                <a
                  href="/Process"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "500" , fontSize:"25px" }}
                >
              Process
                </a>

              
              </li>
              <li className="li">
                <a
                  href="/Faq"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "500" , fontSize:"25px" }}
                >
             Faqs
                </a>

              
              </li>
              
              <li className="li">
                <a
                  href="/Gallery"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "500" , fontSize:"25px" }}
                >
            Gallery
                </a>

              
              </li>
              
              <li className="li">
                <a
                  href="/Appointment"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "500" , fontSize:"25px" }}
                >
              Appointment
                </a>

              
              </li>
               
              <li className="li">
                <a
                  href="/Contact"
                  onClick={() => setToggleMenu(false)}
                  style={{ color: "#fff", fontWeight: "500" , fontSize:"25px" }}
                >
               <button class="button-33" role="button" style={{  fontWeight: "700", textDecoration: "none" }}>  Contact</button>
                </a>

              
              </li>
              
              {" "}


              
              <br/> 
            
              <br/> 
        


 <br/> 
              
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
