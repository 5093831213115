

import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";




import Navbar from '../../Navbar/Navbar'

export default function App() {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className='back_of_contact_us'>
  
 <div className="container">

 <br/>     <br/>     <br/>    <br/>   <br/>    <br/> 
             <h1 className='cracks_h1_faq' data-aos="fade-up">Contact Us</h1>
             
             
               <br/>     <br/>   
               
               <p className='cracks_h1_faqs' data-aos="fade-up">
               Crafting exceptional architecture starts with a conversation; we're eager to hear your  thoughts. Let's connect and start crafting your vision.



               </p>
               <br/>     <br/>      <br/>    <br/>  <br/>    <br/> 

 </div>

           
     
    </div>
  );
}
