import React from 'react';
import { Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

function TopNavbar() {
  return (
    <div className='center_top_navbar'>
      <div className='container'> 
        <Grid container style={{ position: 'relative', top: '9px' }} spacing={2}>
          
        <Grid item md={2} lg={2} xs={12} sm={12} style={{textAlign:"center"}}>
<a href='/Reviews' className='color_of_topnav_social'>Reviews</a>

        </Grid>
        <Grid item md={4} lg={4} xs={12} sm={12}   style={{textAlign:"center"}}>
            <a
              href='tel:+19566596468'
              className='phone-number color_of_topnav_social'
              style={{textAlign:"center"}}
            >
             +1 (956)659-6468
            </a>
          </Grid>
       
        
          <Grid item md={2} lg={2} xs={12} sm={12}  style={{textAlign:"center"}}>
<a href='/AztecCrypto' className='color_of_topnav_social color_azteccrypto' target='_blank'>Azteccrypto</a>

        </Grid>
        <br/>
        </Grid> <br/>
      </div>
    </div>
  );
}

export default TopNavbar;
