import React from 'react'
import ReactPlayer from 'react-player';
import video1 from './video/video.mp4'
import video2 from './video/video2.mp4'
import { Grid } from '@mui/material';


function Yield() {
  return (
    <div className=' '>
      <br/> <br/> <br/> <br/>
      <div className='container'>

      <Grid container spacing={2}>


<Grid item md={6} lg={6} xs={12} sm={12} ><ReactPlayer
     url={video1} // replace with your video URL
     controls={true} // Enable video controls (play, pause, etc.)
     width="100%" // Set the width of the player
     height="auto" // Set the height of the player
   
   /></Grid>

<Grid item md={6} lg={6} xs={12} sm={12} ><ReactPlayer
url={video2} // replace with your video URL
controls={true} // Enable video controls (play, pause, etc.)
width="100%" // Set the width of the player
height="auto" // Set the height of the player
/></Grid>

      </Grid>

      </div>
      
      <br/> <br/> <br/> <br/>
      <div className='container'>

<Grid container spacing={2}>




</Grid>

</div>
    </div>
  )
}

export default Yield