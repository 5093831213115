import React from 'react'
import divider from './image/divider.png'

import { Grid } from '@mui/material'
function SwapDesign() {
  return (
    <div className='bg_of_whyuss'>  <img src={divider} style={{width:"100%" , position:"relative" , bottom:"8px"}}/>
    <br/>
    <br/>  <br/><br/>
    <br/>
    <div className='container'>
    <Grid container spacing={2}>
  <Grid item md={12} lg={12} xs={12} sm={12}>
  <h1 className='whyushead' data-aos="fade-up">Why Choose Us?</h1>
    <br/>  <br/>
    <br/>
    <p className='whyusp' data-aos="fade-up">Choosing the right company is extremely important! Don’t trust your home improvement needs to just anyone, you could be leaving your home’s value hanging in the balance. We are the only company in the Rio Grande Valley who uses VA / FHA & Engineer certified approved methods of repair. We will help protect your investment by ensuring that your home will maintain its maximum value with our annual foundation performance inspections for 10 years following your foundation repair with us.

No other company can offer you that kind of assurance. You may even find it very difficult to sell your home or get a reverse mortgage if you had the WRONG Company perform your foundation repairs. Make sure you make the right choice for your foundation repair needs.

Living in South Texas means living with native clay soils that shrink or swell with changes in moisture content. As these soils go through seasonal wet and dry cycles, a structure’s foundation can undergo stress or noticeable movement. This will eventually result in cosmetic and structural damage. Most residential structures are not built to withstand this stress, and as a result foundation problems are widespread in this area. New technologies in foundation repair have been developed to deal with the ongoing challenges presented by our native soils.

Aztec House Leveling and Remodeling offers foundation repair, floor & concrete leveling, remodeling, roofing, and plumbing services to the South Texas residential and commercial properties. We are licensed, bonded, and insured and we’re able to provide an engineer certification upon request. Our company provides free estimates and senior citizen discounts to all homeowners and our work has a 10 year guarantee with a lifetime transferable warranty. Our inspectors are well trained and qualified.

 </p>
 <br/><br/><br/>


  </Grid>

</Grid>
       </div>
   
   
    </div>
  )
}

export default SwapDesign